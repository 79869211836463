/* src/BlogPost.css */
.blog-post {
    padding: 20px;
    line-height: 1.6;
  }
  
  .blog-post h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .post-content {
    padding: 25px;
    white-space: pre-wrap; /* Preserve whitespace */
    background-color: #333;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .post-content img {
    max-width: 100%; /* Ensure images are responsive */
    max-height: 500px; /* Set a maximum height */
    display: block;
    margin: 10px auto; /* Center images and add margin */
    object-fit: contain; /* Ensure images maintain aspect ratio */
  }
  .post-content p {
    margin: 0 0 10px 0; /* Add margin to paragraphs */
  }
  
  .post-content ul {
    margin: 0 0 10px 0; /* Add margin to lists */
  }
  
  .post-content ol {
    margin: 0 0 10px 0; /* Add margin to ordered lists */
  }
  
  .post-content li {
    margin: 0 0 5px 20px; /* Add margin to list items */
  }
  
  .post-content blockquote {
    margin: 0 0 10px 0; /* Add margin to blockquotes */
    padding-left: 10px; /* Add padding to blockquotes */
    border-left: 3px solid #ccc; /* Add border to blockquotes */
  }
  
  .post-content img {
    max-width: 100%; /* Ensure images are responsive */
    margin: 10px 0; /* Add margin to images */
  }
  