/* src/BlogList.css */
.blog-list {
    padding: 20px;
  }
  
  .blog-list h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .blog-list li {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #333;
    box-shadow: 5px 5px 5px #0000001a;
    border-radius: 5px;
  }
  
  .blog-list li h2 {
    margin: 0;
  }
  
  .blog-list li a {
    text-decoration: none;
    color: #f2f2f2;
  }
  
  .blog-list li a:hover {
    text-decoration: underline;
  }
  