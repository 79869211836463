/* InstitutesTracker component specific styles */
.institutes-tracker {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto; /* Ensure the form is horizontally centered */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Ensure the form is vertically centered */
    height: 120vh; /* Take up the full height of the screen */
}

.institutes-tracker h1, .institutes-tracker h3 {
    /* color: #2c3e50; */
    font-size: 1.5em;
    text-align: center; /* Center the headings */
}

.institutes-tracker p {
    font-size: 1em;
    line-height: 1.6;
    text-align: center; /* Center the paragraph */
}

.institutes-tracker label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.institutes-tracker div {
    padding: 20px;
    background-color: #33333300;
    border-radius: 8px;
    margin: 20px 0;
    width: 100%; /* Ensure divs stretch across the form */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center form elements within the div */
    justify-content: center;
    box-sizing: border-box;
}

.institutes-tracker select,
.institutes-tracker input,
.institutes-tracker button {
    font-size: 1em;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.institutes-tracker select:focus,
.institutes-tracker input:focus,
.institutes-tracker button:focus {
    border-color: #2980b9;
    outline: none;
}

.institutes-tracker button {
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%; /* Ensure buttons stretch across the form */
}

.institutes-tracker button:hover {
    background-color: #2980b9;
}

.institutes-tracker button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    .institutes-tracker {
        padding: 10px;
    }

    .institutes-tracker h1 {
        font-size: 1.6em;
    }

    .institutes-tracker div {
        width: 100%;
        margin: 10px;
        padding: 15px;
    }

    .institutes-tracker input,
    .institutes-tracker select,
    .institutes-tracker button {
        padding: 12px;
    }

    .institutes-tracker p {
        font-size: 0.9em;
    }
}

/* Extra Small Screens */
@media (max-width: 480px) {
    .institutes-tracker h1 {
        font-size: 1.4em;
    }

    .institutes-tracker h3 {
        font-size: 1.1em;
    }

    .institutes-tracker div {
        margin: 10px 0;
        padding: 10px;
    }

    .institutes-tracker input,
    .institutes-tracker select,
    .institutes-tracker button {
        font-size: 0.9em;
        padding: 8px;
    }

    .institutes-tracker p {
        font-size: 0.85em;
    }
}
