/* src/AdminDashboard.css */
.admin-dashboard {
    padding: 20px;
  }
  
  .admin-dashboard h1 {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  
  .admin-dashboard ul {
    list-style-type: none;
    padding: 0;
  }
  
  .admin-dashboard li {
    padding: 10px;
    margin-bottom: 10px;
    /* background-color: #f4f4f4; */
    background-color: rgb(12, 18, 41);
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .admin-dashboard li h2 {
    margin: 0 0 10px 0;
  }
  
  .admin-dashboard button {
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .admin-dashboard button:hover {
    background-color: #c82333;
  }
  