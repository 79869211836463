/* General Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

/* Header Styles */
.App-header {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

/* Navigation Bar */
.navigation {
  /* display: flex; */
}

.navigation button,
#ReturnToMainIndex,
.dropdown-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 5px 5px 5px #0000001a;
}

.navigation button:hover,
.dropdown-button:hover,
#ReturnToMainIndex:hover {
  background-color: #555;
}

/* Dropdown Menu */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 240px;
  overflow-y: auto;
}

.dropdown-content.show {
  display: block;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  color: #ccc;
  cursor: pointer;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  background-color: #727272;
}

/* Page Containers */
.container {
  padding: 20px;
}

.character-study-page,
.note-content-page {
  padding: 20px;
}

/* Sections */
.character-section,
.note-section {
  margin-bottom: 20px;
}

.character-section-title,
.note-section-title {
  background-color: #333;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}

.character-section-content,
.note-section-content {
  display: none;
  padding: 10px;
  background-color: #313131;
}

.character-section-content.active,
.note-section-content.active {
  display: block;
}

/* Memory Game */
.memory-game {
  text-align: center;
  margin-top: 20px;
}

.memory-game input,
.memory-game button {
  margin: 10px 0;
  padding: 5px;
  font-size: 1em;
}

.memory-game button {
  margin: 5px;
}

.memory-game .feedback {
  margin-top: 10px;
  font-size: 1.2em;
}

/* Forum Styles */
.community-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.post-form input,
.post-form textarea,
.comment-form input,
.comment-form textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.post-form textarea,
.comment-form textarea {
  height: 100px;
}

.post-form button,
.comment-form button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.post-form button:hover,
.comment-form button:hover {
  background-color: #0056b3;
}

.posts {
  margin-top: 20px;
}

.post {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.post h3 {
  margin: 0 0 10px;
}

#post-date,
#comment-date {
  margin-top: 20px;
  font-size: small;
}

.comment-button,
#show-hide-comments {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin: 20px 0 10px;
}

.comment-button:hover,
#show-hide-comments:hover {
  background-color: #0056b3;
}

/* Comment Section */
.comments {
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.comment {
  background-color: #e6e6e6;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

/* Dark Mode Styles */
.dark-mode {
  background-color: #222;
  color: #fff;
}

.dark-mode .post-form input,
.dark-mode .post-form textarea,
.dark-mode .comment-form input,
.dark-mode .comment-form textarea {
  background-color: #333;
  color: #fff;
}

.dark-mode button {
  background-color: #555;
}

.dark-mode button:hover {
  background-color: #777;
}

.dark-mode .posts .post {
  background-color: #3a3a3a;
}

.dark-mode .dropdown-content {
  background-color: #444;
}

.dark-mode .comments {
  background-color: #333;
}

.dark-mode .comment {
  background-color: #444;
}

.dark-mode .note-section-title,
.dark-mode .character-section-title {
  background-color: #444;
}

/* Utility Classes */
.password-prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-prompt {
  background: #282c34;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.password-prompt input {
  margin: 10px 0;
  padding: 5px;
  width: 80%;
}

.password-prompt button {
  padding: 5px 10px;
}
