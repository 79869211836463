/* src/AddEditPost.css */


.add-edit-post {
    margin-bottom: 20px;
  }
  
  .add-edit-post input,
  .add-edit-post .ql-container {
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    /* border-radius: 5px; */
    background-color: transparent;
  }

  .title-input {
    width: 97%;
  }
  
  .add-edit-post button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-edit-post button:hover {
    background-color: #218838;
  }
  
  /* src/BlogPost.css */
  .blog-post {
    padding: 20px;
    line-height: 1.6;
  }
  
  .blog-post h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-post div {
    white-space: pre-wrap; /* Preserve whitespace */
  }
  img {
    max-width: 100%; /* Ensure images are responsive */
    max-height: 500px; /* Set a maximum height */
    display: block;
    margin: 10px auto; /* Center images and add margin */
    object-fit: contain; /* Ensure images maintain aspect ratio */
  }